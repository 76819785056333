
/* ----------------------------------------------------------------

	slider.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Flex Slider
-----------------------------------------------------------------*/

:root,
.not-dark {
	--#{$cnvs-prefix}slider-caption-font-color     : #{$slider-caption-font-color};
	--#{$cnvs-prefix}slider-arrows-bg-color        : #{$slider-arrows-bg-color};
	--#{$cnvs-prefix}slider-arrows-bg-hover-color  : #{$slider-arrows-bg-hover-color};
	--#{$cnvs-prefix}slider-arrow-color           : #{$slider-arrow-color};
	--#{$cnvs-prefix}owl-dots-slider-color         : #{$owl-dots-slider-color};
}

:root {
	//-------------------- Sliders --------------------//
	// Flex Slider
	--#{$cnvs-prefix}fslider-dots-position-top   	: #{$fslider-dots-position-top};
	--#{$cnvs-prefix}fslider-dots-position-right 	: #{$fslider-dots-position-right};
	--#{$cnvs-prefix}fslider-dots-size           	: #{$fslider-dots-size};
	--#{$cnvs-prefix}fslider-dots-border-color     : #{$fslider-dots-border-color};
	--#{$cnvs-prefix}fslider-dots-border-radius  	: #{$fslider-dots-border-radius};

	--#{$cnvs-prefix}fslider-thumbs-gutters        : #{$fslider-thumbs-gutters};
	--#{$cnvs-prefix}fslider-thumbs-width          : #{$fslider-thumbs-width}; // for .flex-thumb-grid

	// Canvas Slider
	--#{$cnvs-prefix}canvas-slider-dots-size       : #{$canvas-slider-dots-size};
	--#{$cnvs-prefix}slider-pagination-gutters	 : #{$slider-pagination-gutters};
	--#{$cnvs-prefix}slider-pagination-rounded	 : #{$slider-pagination-rounded};
	--#{$cnvs-prefix}swiper-dots-position-bottom   : #{$swiper-dots-position-bottom};

	--#{$cnvs-prefix}slider-caption-width          : #{$slider-caption-width};
	--#{$cnvs-prefix}slider-caption-font-size      : #{$slider-caption-font-size};
	--#{$cnvs-prefix}slider-caption-font-weight    : #{$slider-caption-font-weight};
	--#{$cnvs-prefix}slider-caption-transition     : #{$slider-caption-transition};
	--#{$cnvs-prefix}slider-caption-p-size         : #{$slider-caption-p-size};
	--#{$cnvs-prefix}slider-caption-offset         : #{$slider-caption-offset};
	--#{$cnvs-prefix}slider-caption-bg-light       : #{$slider-caption-bg-light};
	--#{$cnvs-prefix}slider-caption-bg-dark        : #{$slider-caption-bg-dark};
	--#{$cnvs-prefix}slider-caption-bg-radius      : #{$slider-caption-bg-radius};
	--#{$cnvs-prefix}slider-caption-bg-padding     : #{$slider-caption-bg-padding};

	// Slider Arrows

	--#{$cnvs-prefix}slider-arrows-size            : #{$slider-arrows-size};
	--#{$cnvs-prefix}slider-arrows-sm-size         : calc(var(--#{$cnvs-prefix}slider-arrows-size) - 20px);

	--#{$cnvs-prefix}slider-arrows-border-radius   : #{$slider-arrows-border-radius};
	--#{$cnvs-prefix}slider-arrow-sm-offset        : #{$slider-arrow-sm-offset};

	// Owl Dots
	--#{$cnvs-prefix}owl-dots-slider-position      : #{$owl-dots-slider-position};
}

.fslider,
.fslider .flexslider,
.fslider .slider-wrap,
.fslider .slide,
.fslider .slide > a,
.fslider .slide > img,
.fslider .slide > a > img {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}

.fslider {
	&.h-100 {
		.flexslider,
		.flex-viewport,
		.slider-wrap,
		.slide,
		.slide > a,
		.slide > a > img, {
			height: 100% !important;
		}

		.slide > a > img {
			object-fit: cover;
		}
	}
}

.fslider {
	min-height: 32px;
}


.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
	border: none;
}

.slider-wrap,
.flex-control-nav,
.flex-direction-nav {
	margin: 0;
	padding: 0;
	list-style: none;
	border: none;
}

.flexslider {
	position: relative;
	margin: 0;
	padding: 0;
	.slider-wrap > .slide {
		display: none;
		-webkit-backface-visibility: hidden;
	}
}

.flexslider .slider-wrap img {
	width: 100%;
	display: block;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

.slider-wrap::after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slider-wrap {
	display: block;
}

* html .slider-wrap {
	height: 1%;
}

.no-js .slider-wrap > .slide:first-child {display: block;}

.flex-viewport {
	max-height: 2000px;
	@include transition(all 1s ease);
}

/* ----------------------------------------------------------------
	Slider
-----------------------------------------------------------------*/

.slider-element {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.slider-parallax { position: relative; }

.slider-parallax,
.slider-parallax .swiper-container {
	visibility: visible;
	-webkit-backface-visibility: hidden;
}

.slider-inner {
	position: absolute;
	overflow: hidden;
	opacity: 1;
	visibility: visible;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	.slider-parallax & {
		position: fixed;
	}
}

.h-auto:not(.slider-parallax) .slider-inner,
.h-auto .swiper-container,
.min-vh-0:not(.slider-parallax) .slider-inner,
.min-vh-0 .swiper-container {
	position: relative !important;
	height: auto !important;
}

.slider-parallax-invisible .slider-inner {
	opacity: 0;
	visibility: hidden;
}

@include media-breakpoint-up(lg){
	.h-auto .swiper-wrapper,
	.min-vh-0 .swiper-wrapper {
		height: 75vh;
	}
}

.slider-inner.slider-inner-relative {
	position: relative !important;
	height: auto !important;
}

.device-touch .slider-parallax .slider-inner { position: relative; }

body:not(.side-header) .slider-parallax .slider-inner { left: 0; }

body:not(.stretched) .slider-parallax .slider-inner { left: auto; }

.slider-parallax .slider-inner,
.slider-parallax .slider-caption {
	will-change: transform;
	@include transition(transform 0s linear);
	-webkit-backface-visibility: hidden;
}


@include media-breakpoint-down(lg) {

	#slider {
		display: -ms-flexbox !important;
		display: flex !important;
		flex-direction: column;
		justify-content: center;

		.slider-inner {
			position: relative;
			display: -ms-flexbox;
			display: flex;
			flex-grow: 1;
			align-items: center;
		}
	}
}


/* Boxed Slider
-----------------------------------------------------------------*/

.boxed-slider { padding-top: 40px; }


/* Swiper Slider
-----------------------------------------------------------------*/

.swiper_wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
	.full-screen & { height: auto; }
	.swiper-container {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
	}
	.swiper-slide {
		position: relative;
		margin: 0;
		overflow: hidden;
		[data-caption-animate] { -webkit-backface-visibility: hidden; }
	}
}

.swiper-slide-bg {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.slider-element .video-wrap,
.section .video-wrap,
.swiper-slide .yt-bg-player,
.section .yt-bg-player,
.swiper-slide-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
}

.yt-bg-player .inlinePlayButton,
.yt-bg-player .loading { display: none !important; }

.slider-element .container,
.slider-element .container-fluid {
	position: relative;
	height: 100%;
}

.slider-element.canvas-slider .swiper-slide img { width: 100%; }

.slider-element.canvas-slider a {
	display: block;
	height: 100%;
}


.swiper-pagination {
	position: absolute;
	width: 100%;
	z-index: 20;
	margin: 0;
	top: auto;
	bottom: var(--#{$cnvs-prefix}swiper-dots-position-bottom) !important;
	text-align: center;
	line-height: 1;
	span {
		display: inline-block;
		cursor: pointer;
		width: var(--#{$cnvs-prefix}canvas-slider-dots-size);
		height: var(--#{$cnvs-prefix}canvas-slider-dots-size);
		margin: 0 var(--#{$cnvs-prefix}slider-pagination-gutters);
		opacity: 1;
		background-color: transparent;
		border: 1px solid var(--#{$cnvs-prefix}slider-arrow-color);
		border-radius: var(--#{$cnvs-prefix}slider-pagination-rounded);
		@include transition(all .3s ease);
		&:hover,
		&.swiper-pagination-bullet-active { background-color: var(--#{$cnvs-prefix}slider-arrow-color) !important; }
	}
}


/* Video Overlay
-----------------------------------------------------------------*/

.video-wrap {
	overflow: hidden;
	width: 100%;
	position: relative;
	video {
		position: relative;
		z-index: 1;
		opacity: 1;
		width: 100%;
		@include transition(opacity .3s 1s ease);
		body:not(.has-plugin-html5video) & {
			opacity: 0;
		}
	}
}

.video-overlay,
.video-placeholder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	background: transparent url($video-overlay-bg) repeat;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}

.video-placeholder {
	z-index: 4;
	background-repeat: no-repeat !important;
}


/* Slider Caption
-----------------------------------------------------------------*/

.slider-caption,
.flex-caption {
	--#{$cnvs-prefix}slider-caption-font-color: #{$slider-caption-font-color};
	position: relative;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	flex-flow: column;
	justify-content: center;
	z-index: 20;
	max-width: var(--#{$cnvs-prefix}slider-caption-width);
	color: var(--#{$cnvs-prefix}slider-caption-font-color);
	font-size: var(--#{$cnvs-prefix}slider-caption-font-weight);
	font-weight: var(--#{$cnvs-prefix}slider-caption-font-weight);
	@include transition($slider-caption-transition);
}

.slider-caption {
	h2 {
		color: var(--#{$cnvs-prefix}slider-caption-font-color);
		text-shadow: none;
	}

	h2 {
		font-size: 4.5rem;
		letter-spacing: 0;
		text-transform: none;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			font-size: 5.5vw;
		}
	}
	p {
		margin-top: 2rem;
		margin-bottom: 0.75rem;
		font-size: var(--#{$cnvs-prefix}slider-caption-p-size);
		@include media-breakpoint-down(md) {
			font-size: 2.6vw;
		}
	}
}

.slider-caption.slider-caption-right {
	margin-left: auto;
}

.slider-caption.slider-caption-center {
	position: relative;
	max-width: 800px;
	text-align: center;
	margin: 0 auto;
	left: 0;
	right: 0;
}

.slider-caption-bg {
	padding: var(--#{$cnvs-prefix}slider-caption-bg-padding);
	border-radius: var(--#{$cnvs-prefix}slider-caption-bg-radius);
	background-color: var(--#{$cnvs-prefix} slider-caption-bg-dark);
	top: auto;
	bottom: var(--#{$cnvs-prefix}slider-caption-offset);
	left: var(--#{$cnvs-prefix}slider-caption-offset);
}

.slider-caption-bg.slider-caption-bg-light {
	background-color: var(--#{$cnvs-prefix}slider-caption-bg-light);
	color: var(--#{$cnvs-prefix}contrast-900);
	font-weight: 400;
	text-shadow: none;
}

.slider-caption-top-left {
	top: var(--#{$cnvs-prefix}slider-caption-offset);
	bottom: auto;
}

.slider-caption-top-right {
	top: var(--#{$cnvs-prefix}slider-caption-offset);
	bottom: auto;
	left: auto;
	right: var(--#{$cnvs-prefix}slider-caption-offset);
}

.slider-caption-bottom-right {
	left: auto;
	right: var(--#{$cnvs-prefix}slider-caption-offset);
}


/* Slider Arrows
-----------------------------------------------------------------*/

.slider-element .owl-carousel { margin: 0; }

.slider-arrow-left,
.slider-arrow-right,
.flex-prev,
.flex-next,
.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
	position: absolute;
	cursor: pointer;
	z-index: 10;
	top: 50%;
	left: 0;
	background-color: var(--#{$cnvs-prefix}slider-arrows-bg-color);
	width: var(--#{$cnvs-prefix}slider-arrows-size);
	height: var(--#{$cnvs-prefix}slider-arrows-size);
	border: 0;
	border-radius: 0 var(--#{$cnvs-prefix}slider-arrows-border-radius) var(--#{$cnvs-prefix}slider-arrows-border-radius) 0;
	transform: translateY(-50%);
	@include transition(background-color .3s ease-in-out);
}

.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
	top: auto;
	bottom: 57px;
	left: auto;
	right: var(--#{$cnvs-prefix}slider-arrow-sm-offset);
	margin: 0;
	width: var(--#{$cnvs-prefix}slider-arrows-sm-size);
	height: var(--#{$cnvs-prefix}slider-arrows-sm-size);
	border-radius: var(--#{$cnvs-prefix}slider-arrows-border-radius) var(--#{$cnvs-prefix}slider-arrows-border-radius) 0 0;
}

.slider-arrow-bottom-sm {
	bottom: var(--#{$cnvs-prefix}slider-arrow-sm-offset);
	border-radius: 0 0 var(--#{$cnvs-prefix}slider-arrows-border-radius) var(--#{$cnvs-prefix}slider-arrows-border-radius);
}

.fslider[data-thumbs="true"] .flex-prev,
.fslider[data-thumbs="true"] .flex-next { margin-top: calc(-1 * var(--#{$cnvs-prefix}slider-arrow-sm-offset)); }

.slider-arrow-right,
.slider-element .owl-next,
.flex-next {
	left: auto;
	right: 0;
	border-radius: var(--#{$cnvs-prefix}slider-arrows-border-radius) 0 0 var(--#{$cnvs-prefix}slider-arrows-border-radius);
}

.slider-arrow-left i,
.slider-arrow-right i,
.flex-prev i,
.flex-next i,
.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
	line-height: var(--#{$cnvs-prefix}slider-arrows-size);
	width: 100%;
	height: 100%;
	color: var(--#{$cnvs-prefix}slider-arrow-color);
	font-size: calc(var(--#{$cnvs-prefix}slider-arrows-size) / 1.75);
	text-align: center;
	@include transition(color .3s ease-in-out);
}


.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
	width: var(--#{$cnvs-prefix}slider-arrows-sm-size);
	height: var(--#{$cnvs-prefix}slider-arrows-sm-size);
	margin: 0;
	line-height: calc(var(--#{$cnvs-prefix}slider-arrows-sm-size) - 2px);
	font-size: 18px;
	color: var(--#{$cnvs-prefix}owl-dots-slider-color);
}

.slider-element .owl-prev i { margin-left: 0; }

.slider-element .owl-next i { margin-right: 4px; }

.slider-arrow-left:hover,
.slider-arrow-right:hover,
.flex-prev:hover,
.flex-next:hover,
.slider-arrow-top-sm:hover,
.slider-arrow-bottom-sm:hover { background-color: var(--#{$cnvs-prefix}slider-arrows-bg-hover-color) !important; }


/* Slide Number
-----------------------------------------------------------------*/

.slide-number {
	position: absolute;
	opacity: 0;
	top: auto;
	right: 20px;
	bottom: 20px;
	z-index: 20;
	width: 36px;
	height: 40px;
	text-align: center;
	color: rgba(var(--#{$cnvs-prefix}invert-contrast-rgb), .8);
	font-size: 1.125rem;
	font-weight: 300;
	@include transition(all .3s linear);
}

.slider-element:hover .slide-number { opacity: 1; }

.slide-number-current,
.slide-number-total {
	position: absolute;
	line-height: 1;
	top: 5px;
	left: 0;
}

.slide-number-total {
	top: auto;
	bottom: 5px;
	left: auto;
	right: 0;
}

.slide-number span {
	display: block;
	font-size: 32px;
	line-height: 40px;
}


/* Pagination
-----------------------------------------------------------------*/

.slider-element .owl-dots {
	position: absolute;
	width: 100%;
	z-index: 20;
	margin: 0;
	top: auto;
	@include align-position($owl-dots-slider-position);
}

.slider-element .owl-dots button {
	width: var(--#{$cnvs-prefix}canvas-slider-dots-size);
	height: var(--#{$cnvs-prefix}canvas-slider-dots-size);
	margin: 0 var(--#{$cnvs-prefix}slider-pagination-gutters);
	opacity: 1 !important;
	background-color: transparent !important;
	border: var(--#{$cnvs-prefix}owl-nav-border);
}

.slider-element .owl-dots .owl-dot.active,
.slider-element .owl-dots .owl-dot:hover { background-color: var(--#{$cnvs-prefix}owl-nav-background) !important; }


/* Flex Slider - Navigation
-----------------------------------------------------------------*/

.nav-offset .flex-prev,
.nav-offset .flex-next {
	border-radius: 3px;
}

.nav-offset .flex-prev {
	left: 20px;
}

.nav-offset .flex-next {
	right: 20px;
}

.nav-circle .flex-prev,
.nav-circle .flex-next {
	border-radius: 50%;
}

.nav-solid .flex-prev,
.nav-solid .flex-next {
	opacity: 1;
	background-color: var(--#{$cnvs-prefix}slider-arrow-color);
}

.nav-solid .flex-prev:hover,
.nav-solid .flex-next:hover {
	background-color: #333 !important;
}

.nav-solid .flex-prev:not(:hover) i,
.nav-solid .flex-next:not(:hover) i {
	color: #444;
	text-shadow: none;
}


.nav-size-sm .flex-prev,
.nav-size-sm .flex-next {
	width: var(--#{$cnvs-prefix}slider-arrows-sm-size);
	height: var(--#{$cnvs-prefix}slider-arrows-sm-size);
}

.nav-size-sm .flex-prev i,
.nav-size-sm .flex-next i {
	line-height: var(--#{$cnvs-prefix}slider-arrows-sm-size);
	font-size: 1.5rem;
}

.fslider[class*="nav-pos-"] .flex-prev,
.fslider[class*="nav-pos-"] .flex-next {
	position: relative;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 5px;
	transform: none;
}

.fslider.nav-size-sm[class*="nav-pos-"] .flex-prev,
.fslider.nav-size-sm[class*="nav-pos-"] .flex-next {
	margin: 0 3px;
}

.fslider[class*="nav-pos-"] .flex-direction-nav {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
}

.nav-pos-top .flex-direction-nav,
.nav-pos-top-left .flex-direction-nav,
.nav-pos-top-right .flex-direction-nav {
	top: 20px;
	left: 0;
}

.nav-pos-bottom .flex-direction-nav,
.nav-pos-bottom-left .flex-direction-nav,
.nav-pos-bottom-right .flex-direction-nav {
	top: auto;
	bottom: 20px;
}

.nav-pos-top-left .flex-direction-nav,
.nav-pos-bottom-left .flex-direction-nav {
	width: auto !important;
	left: 16px;
}

.nav-pos-top-right .flex-direction-nav,
.nav-pos-bottom-right .flex-direction-nav {
	width: auto !important;
	left: auto;
	right: 16px;
}


/* Flex Slider - Pagination & Thumbs
-----------------------------------------------------------------*/

.fslider[data-animation="fade"][data-thumbs="true"] .flexslider,
.fslider.testimonial[data-animation="fade"] .flexslider { height: auto !important; }

.flex-control-nav {
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	width: auto;
	z-index: 10;
	text-align: center;
	top: var(--#{$cnvs-prefix}fslider-dots-position-top);
	right: var(--#{$cnvs-prefix}fslider-dots-position-right);
	margin: 0;
}

.flex-control-nav li {
	display: block;
	margin: 0 var(--#{$cnvs-prefix}slider-pagination-gutters);
	width: var(--#{$cnvs-prefix}fslider-dots-size);
	height: var(--#{$cnvs-prefix}fslider-dots-size);
}

.flex-control-nav li a {
	display: block;
	cursor: pointer;
	text-indent: -9999px;
	width: 100% !important;
	height: 100% !important;
	border: 1px solid var(--#{$cnvs-prefix}fslider-dots-border-color);
	color: transparent;
	border-radius: var(--#{$cnvs-prefix}fslider-dots-border-radius);
	@include transition($fslider-dots-transition);
}

.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
	background-color: var(--#{$cnvs-prefix}fslider-dots-border-color);
}


.control-solid .flex-control-nav li a {
	border: none;
	background-color: var(--#{$cnvs-prefix}fslider-dots-border-color);
}

.control-solid .flex-control-nav li:hover a,
.control-solid .flex-control-nav li a.flex-active {
	background-color: var(--#{$cnvs-prefix}themecolor);
}


.control-square .flex-control-nav li a {
	border-radius: 3px;
}

.control-diamond .flex-control-nav li a {
	border-radius: 3px;
	transform: rotate(45deg);
}


/* Flex Slider: Pagination Positions
-----------------------------------------------------------------*/

.control-offset-lg .flex-control-nav {
	top: calc(var(--#{$cnvs-prefix}fslider-dots-position-top + 10));
	right: calc(var(--#{$cnvs-prefix}fslider-dots-position-right + 10));
}

.control-pos-top-left .flex-control-nav,
.control-pos-bottom-left .flex-control-nav {
	left: var(--#{$cnvs-prefix}fslider-dots-position-right);
	right: auto;
}

.control-offset-lg.control-pos-top-left .flex-control-nav,
.control-offset-lg.control-pos-bottom-left .flex-control-nav {
	left: calc(var(--#{$cnvs-prefix}fslider-dots-position-right + 10));
}

.control-pos-bottom-right .flex-control-nav,
.control-pos-bottom-left .flex-control-nav,
.control-pos-bottom .flex-control-nav,
.control-pos-bottom .flex-control-nav {
	top: auto;
	bottom: var(--#{$cnvs-prefix}fslider-dots-position-top);
}

.control-offset-lg.control-pos-bottom-right .flex-control-nav,
.control-offset-lg.control-pos-bottom-left .flex-control-nav,
.control-offset-lg.control-pos-bottom .flex-control-nav,
.control-offset-lg.control-pos-bottom .flex-control-nav {
	bottom: calc(var(--#{$cnvs-prefix}fslider-dots-position-top + 10));
}

.control-pos-bottom .flex-control-nav,
.control-pos-bottom .flex-control-nav,
.control-pos-top .flex-control-nav,
.control-pos-top .flex-control-nav {
	width: 100%;
	right: 0;
}


.control-size-lg .flex-control-nav li {
	width: 0.9325rem;
	height: 0.9325rem;
	margin: 0 5px;
}

/* Flex Slider: Thumbs
-----------------------------------------------------------------*/

.flex-control-nav.flex-control-thumbs {
	position: relative;
	justify-content: left;
	top: 0;
	left: 0;
	right: 0;
	margin: var(--#{$cnvs-prefix}fslider-thumbs-gutters) calc(-1 * var(--#{$cnvs-prefix}fslider-thumbs-gutters)) calc(-1 * var(--#{$cnvs-prefix}fslider-thumbs-gutters)) 0;
	height: auto;
}

.flex-control-nav.flex-control-thumbs li { margin: 0 var(--#{$cnvs-prefix}fslider-thumbs-gutters) var(--#{$cnvs-prefix}fslider-thumbs-gutters) 0; }

.flex-control-nav.flex-control-thumbs li img {
	cursor: pointer;
	text-indent: -9999px;
	border: 0;
	border-radius: 0;
	margin: 0;
	opacity: 0.5;
	@include transition(all .3s ease-in-out);
}

.flex-control-nav.flex-control-thumbs li,
.flex-control-nav.flex-control-thumbs li img {
	display: block;
	width: var(--#{$cnvs-prefix}fslider-thumbs-width) !important;
	height: auto !important;
}

.flex-control-nav.flex-control-thumbs li:hover img,
.flex-control-nav.flex-control-thumbs li img.flex-active {
	border-width: 0;
	opacity: 1;
}


/* FlexSlider: Thumbs - Flexible
-----------------------------------------------------------------*/

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs {
	margin: var(--#{$cnvs-prefix}fslider-thumbs-gutters) calc(-1 * var(--#{$cnvs-prefix}fslider-thumbs-gutters)) calc(-1 * var(--#{$cnvs-prefix}fslider-thumbs-gutters)) 0;
	height: auto;
	display: flex;
	flex-wrap: wrap;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
	flex-shrink: 0;
	flex: 0 0 auto;
	max-width: 100%;
	height: auto !important;
	margin: 0;
	padding: 0 var(--#{$cnvs-prefix}fslider-thumbs-gutters) var(--#{$cnvs-prefix}fslider-thumbs-gutters) 0;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
	width: 25% !important;
}


@each $slider-grid in (3,5,6,8,10,12) {

	.fslider.flex-thumb-grid.grid-#{$slider-grid} .flex-control-nav.flex-control-thumbs li {
		width: divide(100%, $slider-grid) !important;
	}
}

// @include slider-grids(12);

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li img {
	width: 100% !important;
	height: auto !important;
}
