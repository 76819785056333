
/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	FAQ List
-----------------------------------------------------------------*/

.faqlist {
	li {
		margin-bottom: 5px;
		font-size: 1rem;
		a {
			color: var(--#{$cnvs-prefix}contrast-700);
			&:hover {
				color: var(--#{$cnvs-prefix}themecolor) !important;
			}
		}
	}
}
