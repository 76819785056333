
/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/
$client-prefix: clients-grid;

.#{$client-prefix} {
	--#{$cnvs-prefix}clients-grid-padding       :          #{$clients-grid-padding};
	--#{$cnvs-prefix}clients-grid-border-size   :          #{$clients-grid-border-size};
	--#{$cnvs-prefix}clients-grid-border-style  :          #{$clients-grid-border-style};
	--#{$cnvs-prefix}clients-grid-border-color  :          #{$clients-grid-border-color};
	--#{$cnvs-prefix}clients-img-opacity        :          #{$clients-img-opacity};

	position: relative;
	-ms-flex-align: stretch;
	align-items: stretch;
	list-style: none;
	overflow: hidden;
	> .col {
		position: relative;
		padding: var(--#{$cnvs-prefix}clients-grid-padding) 0;
		-webkit-backface-visibility: hidden;
		a {
			display: block;
			width: 75%;
			margin-left: auto;
			margin-right: auto;
			opacity: var(--#{$cnvs-prefix}clients-img-opacity);
			@include transition(all .5s ease);

			&:hover { opacity: 1; }
		}

		img {
			width: 100%;
			display: block;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
		}

		&::before {
			height: 100%;
			top: 0;
			left: calc(-1 * var(--#{$cnvs-prefix}clients-grid-border-size));
			border-left: var(--#{$cnvs-prefix}clients-grid-border-size) var(--#{$cnvs-prefix}clients-grid-border-style) var(--#{$cnvs-prefix}clients-grid-border-color );
		}

		&::after {
			width: 100%;
			height: 0;
			top: auto;
			left: 0;
			bottom: calc(-1 * var(--#{$cnvs-prefix}clients-grid-border-size));
			border-bottom: var(--#{$cnvs-prefix}clients-grid-border-size) var(--#{$cnvs-prefix}clients-grid-border-style) var(--#{$cnvs-prefix}clients-grid-border-color );
		}
	}
}