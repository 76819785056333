/*-----------------------------------------------------------------------------------

	Shortcodes: pricing.scss

-----------------------------------------------------------------------------------*/

$pricing-prefix: pricing;

/* ----------------------------------------------------------------
	Pricing Boxes
-----------------------------------------------------------------*/



.#{$pricing-prefix},
[class*=#{$pricing-prefix}-] {
	--#{$cnvs-prefix}pricing-base-color :         	#{$pricing-base-color};
	--#{$cnvs-prefix}pricing-background :         	 #{$pricing-background};

	--#{$cnvs-prefix}pricing-border-size   :         #{$pricing-border-size};
	--#{$cnvs-prefix}pricing-border-color  :         #{$pricing-border-color};
	--#{$cnvs-prefix}pricing-border-radius :         #{$pricing-border-radius};

	--#{$cnvs-prefix}pricing-title-padding        :         #{$pricing-title-padding};
	--#{$cnvs-prefix}pricing-title-bg             :          #{$pricing-title-bg};
	--#{$cnvs-prefix}pricing-title-border         :          #{$pricing-title-border};
	--#{$cnvs-prefix}pricing-title-font-size      :         #{$pricing-title-font-size};
	--#{$cnvs-prefix}pricing-title-font-weight    :         #{$pricing-title-font-weight};
	--#{$cnvs-prefix}pricing-title-font-spacing   :         #{$pricing-title-font-spacing};
	--#{$cnvs-prefix}pricing-title-font-transform :         #{$pricing-title-font-transform};
	--#{$cnvs-prefix}pricing-title-font-color     :         #{$pricing-title-font-color};

	--#{$cnvs-prefix}pricing-price-padding             :          #{$pricing-price-padding};
	--#{$cnvs-prefix}pricing-price-color               :          #{$pricing-price-color};
	--#{$cnvs-prefix}pricing-price-font-size           :         #{$pricing-price-font-size};
	--#{$cnvs-prefix}pricing-price-bottom-border-width :         #{$pricing-price-bottom-border-width};
	--#{$cnvs-prefix}pricing-price-bottom-border-size  :          #{$pricing-price-bottom-border-size};
	--#{$cnvs-prefix}pricing-price-currency-icon       :          #{$pricing-price-currency-icon};
	--#{$cnvs-prefix}pricing-price-month               :          #{$pricing-price-month};

	--#{$cnvs-prefix}pricing-features-padding      :          #{$pricing-features-padding};
	--#{$cnvs-prefix}pricing-features-font-padding :         #{$pricing-features-font-padding};
	--#{$cnvs-prefix}pricing-features-font-size    :         #{$pricing-features-font-size};

	--#{$cnvs-prefix}pricing-popular-bg                :          #{$pricing-popular-bg};
	--#{$cnvs-prefix}pricing-popular-title-padding     :          #{$pricing-popular-title-padding};
	--#{$cnvs-prefix}pricing-popular-font-color        :          #{$pricing-popular-font-color};
	--#{$cnvs-prefix}pricing-popular-title-font-size   :          #{$pricing-popular-title-font-size};
	--#{$cnvs-prefix}pricing-popular-pricing-font-size :          #{$pricing-popular-pricing-font-size};
	--#{$cnvs-prefix}pricing-popular-box-shadow        :         #{$pricing-popular-box-shadow};

	--#{$cnvs-prefix}pricing-minimal-bg :          #{$pricing-minimal-bg};

	--#{$cnvs-prefix}pricing-extended-padding :         #{$pricing-extended-padding};

}
.#{$pricing-prefix} {
	position: relative;
	> [class^="col-"] {
		margin-top: 1.25rem;
	}
	/* Pricing Boxesma
	-----------------------------------------------------------------*/
	&-box {
		position: relative;
		overflow: hidden;
		border: var(--#{$cnvs-prefix}pricing-border-size) solid var(--#{$cnvs-prefix}pricing-border-color);
		border-radius: var(--#{$cnvs-prefix}pricing-border-radius);
		background-color: var(--#{$cnvs-prefix}pricing-background);
	}
	/* Pricing Boxes - Title
	-----------------------------------------------------------------*/
	&-title {
		padding: var(--#{$cnvs-prefix}pricing-title-padding) 0;
		background-color: var(--#{$cnvs-prefix}pricing-title-bg);
		border-bottom: var(--#{$cnvs-prefix}pricing-title-border);
		letter-spacing: 1px;
		h3 {
			margin: 0;
			font-size: var(--#{$cnvs-prefix}pricing-title-font-size);
			font-weight: var(--#{$cnvs-prefix}pricing-title-font-weight);
			color: var(--#{$cnvs-prefix}pricing-title-font-color);
		}
		span {
			display: block;
			color: var(--#{$cnvs-prefix}contrast-600);
			font-weight: 300;
			font-size: calc(var(--#{$cnvs-prefix}pricing-title-font-size) * 0.7);
			margin: 3px 0;
			text-transform: var(--#{$cnvs-prefix}pricing-title-font-transform);
		}

		&.title-sm {
			h3 {
				font-size: calc(var(--#{$cnvs-prefix}pricing-title-font-size) * 0.8333);
			}
			span {
				font-size: calc(
					var(--#{$cnvs-prefix}pricing-title-font-size / 1.714)
				);
			}
		}
	}
	/* Pricing Boxes - Price
	-----------------------------------------------------------------*/
	&-price {
		position: relative;
		padding: var(--#{$cnvs-prefix}pricing-price-padding) 0;
		color: var(--#{$cnvs-prefix}pricing-price-color);
		font-size: var(--#{$cnvs-prefix}pricing-price-font-size);
		line-height: 1;
		.price-unit {
			display: inline-block;
			vertical-align: top;
			margin: 7px 3px 0 0;
			font-size: var(--#{$cnvs-prefix}pricing-price-currency-icon);
			font-weight: normal;
		}
		.price-tenure {
			vertical-align: baseline;
			font-size: var(--#{$cnvs-prefix}pricing-price-month);
			font-weight: normal;
			letter-spacing: 1px;
			color: var(--#{$cnvs-prefix}contrast-600);
			margin: 0 0 0 3px;
		}
	}
	/* Pricing Boxes - Features
	-----------------------------------------------------------------*/
	&-features {
		border-top: var(--#{$cnvs-prefix}pricing-border-size) solid var(--#{$cnvs-prefix}pricing-border-color);
		border-bottom: var(--#{$cnvs-prefix}pricing-border-size) solid var(--#{$cnvs-prefix}pricing-border-color);
		background-color: var(--#{$cnvs-prefix}contrast-100);
		padding: var(--#{$cnvs-prefix}pricing-features-padding) 0;
		ul {
			margin: 0;
			list-style: none;
		}
		li {
			padding: var(--#{$cnvs-prefix}pricing-features-font-padding) 0;
		}
	}
	/* Pricing Boxes - Action
	-----------------------------------------------------------------*/
	&-action {
		padding: calc(var(--#{$cnvs-prefix}pricing-title-padding) * 1.25) 0;
	}

	/* Pricing Boxes - Best Price
	-----------------------------------------------------------------*/
	&-highlight {
		box-shadow: var(--#{$cnvs-prefix}pricing-popular-box-shadow);
		z-index: 10;
		@if $pricing-popular-title-padding > 30 {
			margin: calc(var(--#{$cnvs-prefix}pricing-popular-title-padding) * -0.5) calc(-1 * var(--#{$cnvs-prefix}pricing-border-size));
		} @else {
			margin: calc(-1 * var(--#{$cnvs-prefix}pricing-popular-title-padding)) calc(-1 * var(--#{$cnvs-prefix}pricing-border-size));
		}
		.#{$pricing-prefix}-title,
		.#{$pricing-prefix}-features {
			background-color: var(--#{$cnvs-prefix}pricing-background);
		}

		.#{$pricing-prefix}-price {
			font-size: var(--#{$cnvs-prefix}pricing-popular-pricing-font-size);
		}
	}

	/* Pricing Boxes - Simple
	-----------------------------------------------------------------*/

	&-simple {
		border: 0;
		.#{$pricing-prefix}-title,
		.#{$pricing-prefix}-features {
			border: 0;
			background-color: transparent;
		}
	}

	/* Pricing Boxes - Minimal
	-----------------------------------------------------------------*/
	@at-root .#{$pricing-prefix}-minimal {
		.#{$pricing-prefix}-price {
			background-color: var(--#{$cnvs-prefix}pricing-minimal-bg);
			border-bottom: var(--#{$cnvs-prefix}pricing-border-size) solid var(--#{$cnvs-prefix}pricing-border-color);
			&::after {
				display: none;
			}
		}
	}

	/* Pricing Boxes - Extended
	-----------------------------------------------------------------*/
	@at-root .#{$pricing-prefix}-extended {
		background-color: var(--#{$cnvs-prefix}pricing-minimal-bg);
		text-align: left;
		.#{$pricing-prefix}-title {
			background-color: transparent;
			padding-top: 0;
			text-align: left;
		}
		.#{$pricing-prefix}-features {
			border: 0;
			background-color: transparent;
		}
		.#{$pricing-prefix}-action-area {
			border-left: var(--#{$cnvs-prefix}pricing-title-border);
			background-color: var(--#{$cnvs-prefix}pricing-title-bg);
			padding: var(--#{$cnvs-prefix}pricing-extended-padding);
			text-align: center;
			.#{$pricing-prefix}-price,
			.#{$pricing-prefix}-action {
				padding: 0;
			}
			.#{$pricing-prefix}-price {
				padding-bottom: var(--#{$cnvs-prefix}pricing-popular-title-padding);
				&::after {
					display: none;
				}
				span.price-tenure {
					display: block;
					margin: 10px 0 0 0;
					font-weight: 300;
					text-transform: uppercase;
					font-size: 0.875rem;
				}
			}
		}
		.#{$pricing-prefix}-meta {
			color: var(--#{$cnvs-prefix}contrast-600);
			font-weight: 300;
			font-size: calc(var(--#{$cnvs-prefix}pricing-title-font-size) * 0.7);
			letter-spacing: var(--#{$cnvs-prefix}pricing-title-font-spacing);
			text-transform: var(--#{$cnvs-prefix}pricing-title-font-transform);
			padding-bottom: 10px;
		}
	}

	/* Pricing Boxes - 5 Columns
	-----------------------------------------------------------------*/
	&.#{$pricing-prefix}-5 {
		.#{$pricing-prefix}-box {
			float: left;
			width: 20%;
			margin-top: var(--#{$cnvs-prefix}pricing-popular-title-padding);
			&.best-price {
				margin-top: 0;
			}
			&:nth-child(5) {
				margin-left: calc(-1 * var(--#{$cnvs-prefix}pricing-border-size));
			}
		}
		.#{$pricing-prefix}-title h3 {
			font-size: var(--#{$cnvs-prefix}pricing-title-font-size);
		}
		.best-price .#{$pricing-prefix}-title h3 {
			font-size: var(--#{$cnvs-prefix}pricing-title-font-size);
		}
	}
}

.table-comparison {
	text-align: center;
	th {
		@extend .table-comparison;
	}
	& th:first-child,
	& td:first-child {
		text-align: left;
		font-weight: bold;
	}
}
